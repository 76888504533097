import React, { useState } from "react";

const Particles1 = () => {

    const [initL, setInitL] = useState(false);

    const particlesLoaded = (container) => {
        console.log(container);
    };

    return (
        <>

        </>
    )
}

export default Particles1