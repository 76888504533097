import React from "react";
import { Formik, Form, Field } from 'formik';
import * as Yup from "yup";
import "./style.css"
import {sendMessage} from "../../../helpers/requestHelper";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import axiosApi from "../../../helpers/api_helper";


const FormContact = props => {

    const  validationSchema = Yup.object().shape({
        name: Yup.string().required("Por favor ingresa tu nombre completo"),
        phone: Yup.string().required("Por favor ingresa tu número de teléfono"),
        email: Yup.string().required("Por favor ingresa tu email"),
        message: Yup.string().required("Por favor ingresa tus comentarios"),
    });

    const valInit =   {
        name: '',
            phone: '',
            email: '',
            message: '',
    }

    const handleSendMsg = async (values, act)  => {
        try {
            await axiosApi({
                url: "msg-send",
                method: "POST",
                data: values,
            }).then((resp) => {
                console.info(resp)
                if(resp.data.success) {
                    act.resetForm(valInit)
                    toast.success(resp.data.data.text, {
                        position: "top-center"
                    });
                } else {

                    toast.error(resp.data.data.text, {
                        position: "top-center"
                    });
                }
            });
        } catch (e) {
            // handle error
            console.error(e);
        } finally {

        }

    }

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                            <div className="left-title">
                                <div className="section-title pb-37">
                                    <h2 className="title">Ponte en Contacto </h2>
                                    <div className="line"></div>
                                </div>
                                <h4 className="sub-title">¡Comienza con una prueba gratis hoy mismo! Contáctanos al
                                    whatsapp, email o desde el formulario y descubre cómo Scala puede transformar tu
                                    escuela.</h4>
                            </div>
                        </div>
            </div>
            <div className="row">
                <div className="col-lg-9 col-md-12">
                            <Formik validationSchema={validationSchema}
                                    onSubmit={(values,actions) => {
                                        handleSendMsg(values, actions)

                                    }}
                                    initialValues={valInit}>
                                {({ errors, touched }) => (
                                    <Form className="contact-form">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12">
                                                <div className="form-group form-group-contact">
                                                    <Field type="text" className="form-control" id="uname"
                                                           placeholder="Nombre completo" name="name" required=""/>
                                                    {errors.name && touched.name ? (
                                                        <label className="label-error">{errors.name}</label>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12">
                                                <div className="form-group">
                                                    <Field type="tel" className="form-control" id="phone" placeholder="Teléfono"
                                                           name="phone" required=""/>
                                                    {errors.phone && touched.phone ? (
                                                        <label className="label-error">{errors.phone}</label>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <Field type="Email" className="form-control" id="pwd" placeholder="Email"
                                                           name="email"/>
                                                    {errors.email && touched.phone ? (
                                                        <label className="label-error">{errors.email}</label>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <Field as="textarea" name="message" className="form-control" placeholder="Ingresa tu mensaje"/>
                                                {errors.message && touched.message ? (
                                                    <label className="label-error">{errors.email}</label>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 text-right">
                                                <button type="submit" className="main-btn">Enviar <span
                                                    className="send-icon"><i
                                                    className="lni lni-telegram-original"></i></span></button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                </div>
                <div className="col-lg-3 con-detail">

                            <ul>
                                <li>
                                    <h4>Email</h4>
                                </li>
                                <li>
                                    <p>hola@scalapp.com</p>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <h4>Whatsapp o Llama</h4>
                                </li>
                                <li>
                                    <p>951 269 0441 </p>
                                </li>
                            </ul>
                        </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default FormContact