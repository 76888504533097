import React from "react";
import Logo from "./../../assests/images/logo.png"
import * as Yup from "yup";
import {sendMessage} from "../../helpers/requestHelper";
import {Form, Formik} from "formik";
const Footer = props => {

    const  validationSchema = Yup.object().shape({
        email: Yup.string().required("Por favor ingresa tu email"),
    });

    const valInit =   {
        email: '',
    }

    const handleSendMsg = (values, act) => {
        const resp= sendMessage(values)
        if(resp.response) {
            act.resetForm(valInit)
        }
    }

    return (
        <>
            <footer id="footer" className="same-section-spacing footer-bg footer-area pt-120">
                <div className="container">
                    <div className="subscribe-area wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="subscribe-content mt-45">
                                    <h2 className="subscribe-title">Proporciona tu
                                        email <span> para mayor información </span></h2>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="subscribe-form mt-50">
                                    <Formik validationSchema={validationSchema}
                                        onSubmit={(values,actions) => {
                                            handleSendMsg(values, actions)
                                    }}
                                    initialValues={valInit}>
                                    {({ errors, touched }) => (
                                        <Form>
                                            <div className="form-group form-group-contact">
                                                <input type="email" name="email" placeholder="Ingresa tu eamil"/>
                                                {errors.email && touched.email ? (
                                                    <label className="label-error">{errors.email}</label>
                                                ) : null}
                                            </div>
                                                <button className="main-btn">Enviar</button>
                                        </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <h5 className="mt-20">
                                    No te preocupes, NO enviamos spam ;)
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-12">
                            <div className="footer-about">
                                <a className="logo" href="#">
                                    <img src={Logo} alt="logo"/>
                                </a><br/>
                                <ul style={{display:"none"}} className="social">
                                    <li><a href="#"><i className="lni lni-facebook-filled"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="">
                                <div className="footer-listing">
                                    <ul style={{display:"none"}} className="heading">
                                        <li><a href="#">Preguntas frecuentes</a></li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="">
                                <div className="footer-listing">
                                    <ul style={{display:"none"}}  className="heading">
                                        <li><a href="#"> Aviso de Privacidad</a></li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="">
                                <div className="footer-listing">
                                    <ul className="heading">
                                        <li><a href="#">Términos de uso</a></li>

                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="copyright">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <p className="heading">© 2024 Scala. All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="particles-2">
                </div>
            </footer>
        </>
    )
}

export default Footer