import axios from "axios";

//apply base url for axios
const API_URL = process.env.REACT_APP_URL_API;

//APPLY BASE URL FOR PLATAFORM FRONTEND
export const PORTAL_URL = process.env.REACT_APP_URL_PORTAL

const axiosApi = axios.create({
    baseURL: API_URL,
});

axiosApi.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
    return await axiosApi
        .get(url, { ...config })
        .then((response) => response.data);
}

export async function post(url, data, config = {}) {
    return axiosApi
        .post(url, { ...data }, { ...config })
        .then((response) => response.data);
}

export default axiosApi;