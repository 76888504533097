import React, { useState } from "react";
import HeaderHome from "../layout/HeaderHome";
import Footer from "../layout/Footer";

import imgServicesShape from "./../../assests/images/services-shape.svg"
import imgServicesShape1 from "./../../assests/images/services-shape-1.svg"
import imgServicesShape2 from "./../../assests/images/services-shape-2.svg"
import imgServicesShape3 from "./../../assests/images/services-shape-3.svg"
import about1 from "./../../assests/images/about1.png"
import configEasy from "./../../assests/images/configEasy.png"
import aboutSupport from "./../../assests/images/aboutSupport.png"
import aboutShape1 from "./../../assests/images/about-shape-1.svg"
import aboutShape2 from "./../../assests/images/about-shape-2.svg"
import imgDots from "./../../assests/images/dots.svg"
import imgVideo from "./../../assests/images/video.png"
import FormContact from "../common/FormContact/FormContact";
import {Button, Modal} from "react-bootstrap";
import SectionFormContact from "../common/FormContact/SectionFormContact";
const Home = props => {

    const [modalShow, setModalShow] = useState(false);
    const handleModalClose = () => setModalShow(false);
    const handleModalShow = () => setModalShow(true);

    return (
        <>
            <HeaderHome></HeaderHome>
            <section id="features" className="services-area pt-0">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="section-title text-center pb-40">
                                <h3 className="title">Descubre las Funcionalidades Clave <br/>para una Gestión Escolar
                                    Eficiente y Efectiva
                                </h3>
                                <br/>
                                <div className="line m-auto"></div>
                                <p className="text wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.8s">
                                    Cada herramienta está diseñada para facilitar y mejorar todos los aspectos de la
                                    administración escolar
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="single-services text-center wow fadeIn" data-wow-duration="1s"
                                 data-wow-delay="0.8s">
                                <div className="services-icon">
                                    <img className="shape" src={imgServicesShape} alt="shape"/>
                                    <img className="shape-1" src={imgServicesShape3} alt="shape"/>
                                    <i className="flaticon-customer"></i>
                                </div>
                                <div className="services-content mt-30">
                                    <h4 className="services-title"><a href="#">Prospectos </a></h4>
                                    <p className="text">Automatiza el proceso de prospección desde altas, tareas y
                                        cierre de ventas. </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="single-services text-center  wow fadeIn" data-wow-duration="1s"
                                 data-wow-delay="0.2s">
                                <div className="services-icon">
                                    <img className="shape" src={imgServicesShape} alt="shape"/>
                                    <img className="shape-1" src={imgServicesShape3} alt="shape"/>
                                    <i className="flaticon-adaptive"></i>
                                </div>
                                <div className="services-content mt-30">
                                    <h4 className="services-title"><a href="#">Estudiantes e ingresos </a></h4>
                                    <p className="text">Centraliza y simplifica la gestión de alumnos, pagos, aplicación
                                        de becas y descuentos. </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="single-services text-center  wow fadeIn" data-wow-duration="1s"
                                 data-wow-delay="0.2s">
                                <div className="services-icon">
                                    <img className="shape" src={imgServicesShape} alt="shape"/>
                                    <img className="shape-1" src={imgServicesShape3} alt="shape"/>
                                    <i className="flaticon-ui"></i>
                                </div>
                                <div className="services-content mt-30">
                                    <h4 className="services-title"><a href="#">Configura recargos </a></h4>
                                    <p className="text">Persoliza y configura recargos automáticos en el perfil de tu
                                        escuela. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="single-services text-center  wow fadeIn" data-wow-duration="1s"
                                 data-wow-delay="0.2s">
                                <div className="services-icon">
                                    <img className="shape" src={imgServicesShape} alt="shape"/>
                                    <img className="shape-1" src={imgServicesShape3} alt="shape"/>
                                    <i className="lni lni-bar-chart"></i>
                                </div>
                                <div className="services-content mt-30">
                                    <h4 className="services-title"><a href="#">Descarga Reportes</a></h4>
                                    <p className="text">Consulta información de ingresos por periodo, concepto,
                                        cursos. </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="single-services text-center  wow fadeIn" data-wow-duration="1s"
                                 data-wow-delay="0.2s">
                                <div className="services-icon">
                                    <img className="shape" src={imgServicesShape} alt="shape"/>
                                    <img className="shape-1" src={imgServicesShape3} alt="shape"/>
                                    <i className="flaticon-vision"></i>
                                </div>
                                <div className="services-content mt-30">
                                    <h4 className="services-title"><a href="#">Configura cursos, horarios </a></h4>
                                    <p className="text">Crea el catálogo de cursos y horarios de tu escuela y grupos por
                                        fecha de inicio de clases. </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="single-services text-center wow fadeIn" data-wow-duration="1s"
                                 data-wow-delay="0.5s">
                                <div className="services-icon">
                                    <img className="shape" src={imgServicesShape} alt="shape"/>
                                    <img className="shape-1" src={imgServicesShape3} alt="shape"/>
                                    <i className="lni lni-ticket-alt"></i>
                                </div>
                                <div className="services-content mt-30">
                                    <h4 className="services-title"><a href="#"> Centraliza ventas </a></h4>
                                    <p className="text">Administra las compras, venta e inventario de material
                                        didactivo </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="about" className="about-area pt-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s"
                                 data-wow-delay="0.5s">
                                <div className="section-title">
                                    <h3 className="title"><span>¿Por que contratar</span> Scala<span>?</span>
                                    </h3>
                                    <br/>
                                    <div className="line"></div>
                                </div>
                                <p className="text">Contratar Scala para tu plantel significa optar por una solución
                                    integral que transforma la gestión educativa. Con Scala, centralizas el registro de
                                    alumnos, la administración de colegiaturas, becas y aplicación de descuentos, todo
                                    en una plataforma segura. Nuestro software permite a tu equipo centrarse en lo que
                                    realmente importa: ofrecer una educación de calidad. Scala se adapta a las
                                    necesidades específicas de tu institución, garantizando una implementación fluida y
                                    un soporte continuo. Apuesta por Scala y lleva la gestión de tu plantel al siguiente
                                    nivel.</p>
                                <button type="button" onClick={handleModalShow} className="main-btn">Solicita un Demo</button>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-image text-center mt-50 wow fadeInRightBig" data-wow-duration="1s"
                                 data-wow-delay="0.5s">
                                <img src={about1} alt="about"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-shape-1">
                    <img src={aboutShape1} alt="shape"/>
                </div>
            </section>
            <section className="about-area pt-70">
                <div className="about-shape-2">
                    <img src={aboutShape2} alt="shape"/>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 order-lg-last">
                            <div className="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s"
                                 data-wow-delay="0.5s">
                                <div className="section-title">
                                    <h3 className="title"> Configuración y flujo Sencillo <span> </span></h3>
                                    <br/>
                                    <div className="line"></div>
                                </div>
                                <p className="text">Con Scala disfrutarás de una configuración rápida y un flujo de
                                    trabajo sencillo que se adapta a tu institución educativa. Nuestra plataforma está
                                    diseñada para ser de fácil uso, lo que permite a los administradores gestionar sus
                                    tareas diarias sin complicaciones. Cada proceso está optimizado para ahorrar tiempo
                                    y reducir errores. </p>
                                <button type="button" onClick={handleModalShow} className="main-btn">Solicita un Demo
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-6 order-lg-first">
                            <div className="about-image text-center mt-50 wow fadeInRightBig" data-wow-duration="1s"
                                 data-wow-delay="0.5s">
                                <img src={about1} alt="about"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-area pt-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s"
                                 data-wow-delay="0.5s">
                                <div className="section-title">
                                    <h3 className="title">Soporte
                                        personalizado <span> en el proceso de implementación </span></h3>
                                    <br/>
                                    <div className="line"></div>
                                </div>
                                <p className="text">Con Scala no estarás solo en el proceso de implementación. Nuestro
                                    soporte personalizado garantiza una transición suave y efectiva. Estamos contigo en
                                    cada paso, adaptando la plataforma a las necesidades específicas de tu institución y
                                    asegurando que todos los usuarios se sientan cómodos al utilizar nuestras
                                    herramientas.</p>
                                <button type="button" onClick={handleModalShow} className="main-btn">Solicita un Demo</button>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-image text-center mt-50 wow fadeInRightBig" data-wow-duration="1s"
                                 data-wow-delay="0.5s">
                                <img src={aboutSupport} alt="about"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about-shape-1">
                    <img src={aboutShape1} alt="shape"/>
                </div>
            </section>
            <section id="facts" className="video-counter pt-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 order-lg-last">
                            <div className="counter-wrapper mt-50 wow fadeIn" data-wow-duration="1s"
                                 data-wow-delay="0.8s">
                                <div className="counter-content">
                                    <div className="section-title">
                                        <h3 className="title"> Costos personalizados <span> Invierte solo lo que necesitas</span>
                                        </h3>
                                        <br/>
                                        <div className="line"></div>
                                    </div>
                                    <p className="text">En Scala entendemos que cada institución es única. Por eso,
                                        ofrecemos costos personalizados para que inviertas solo en las funcionalidades
                                        que realmente necesitas. Nuestro modelo flexible asegura que obtengas el máximo
                                        valor sin gastos innecesarios, adaptándonos a tu presupuesto y creciendo junto a
                                        tu plantel.</p>
                                    <button type="button" onClick={handleModalShow} className="main-btn mt-5">Solicita un
                                        Demo
                                    </button>
                                </div>
                                <div style={{display: "none"}} className="row no-gutters">
                                <div className="col-4">
                                        <div
                                            className="single-counter counter-color-1 d-flex align-items-center justify-content-center">
                                            <div className="counter-items text-center">
                                                <span className="count"><span className="counter">125</span>K</span>
                                                <p className="text">Downloads</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div
                                            className="single-counter counter-color-2 d-flex align-items-center justify-content-center">
                                            <div className="counter-items text-center">
                                                <span className="count"><span className="counter">87</span>K</span>
                                                <p className="text">Active Users</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div
                                            className="single-counter counter-color-3 d-flex align-items-center justify-content-center">
                                            <div className="counter-items text-center">
                                                <span className="count"><span className="counter">4.8</span></span>
                                                <p className="text">User Rating</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 order-lg-first">
                            {
                                false && <div className="video-content mt-50 wow fadeIn" data-wow-duration="1s"
                                              data-wow-delay="0.5s">
                                    <img className="dots" src={imgDots} alt="dots"/>
                                    <div className="video-wrapper">
                                        <div className="video-image">
                                            <img src={imgVideo} alt="video"/>
                                        </div>
                                        <div className="video-icon">
                                            <a href="https://www.youtube.com/watch?v=r44RKWyfcFw" className="video-popup"><i
                                                className="lni lni-play"></i></a>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="about-image text-center mt-50 wow fadeInRightBig" data-wow-duration="1s"
                                 data-wow-delay="0.5s">
                                <img src={configEasy} alt="about"/>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
            <SectionFormContact/>
            <Footer/>
            <Modal show={modalShow} size="lg" onHide={handleModalClose} aria-labelledby="contained-modal-title-vcenter"
                   centered>
                <Modal.Header closeButton>
                    <Modal.Title>Ponte en contacto para conseguir tu prueba </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormContact/>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Home