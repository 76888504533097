import React from "react";
import Navbar from "./Navbar";
import SectionStart from "./SectionStart";

const HeaderHome = props => {

    return (
        <>
            <header className="header-area">
                <Navbar/>
                <SectionStart/>
            </header>
        </>
    )
}

export default HeaderHome