import React, {useState} from "react";

import Logo from "../../assests/images/logo.png";
const Navbar = props => {

    const [navBarCollapseShow,setNavBarCollapseShow] = useState(false)

    const handleNavBarCollapse = () => {
      setNavBarCollapseShow(!navBarCollapseShow)
    }

    return (
        <div className="navbar-area sticky">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <nav className="navbar navbar-expand-lg">
                            <a className="" href="index-05.html">
                                <img src={Logo} alt="logo"/>
                            </a>
                            <button onClick={handleNavBarCollapse} className={ navBarCollapseShow ? "navbar-toggler active":"navbar-toggler"} type="button" data-toggle="collapse"
                                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                <span className="toggler-icon"></span>
                                <span className="toggler-icon"></span>
                                <span className="toggler-icon"></span>
                            </button>
                            <div className={ navBarCollapseShow ? "show collapse navbar-collapse sub-menu-bar " : " collapse navbar-collapse sub-menu-bar"} id="navbarSupportedContent">
                                <ul id="nav" className="navbar-nav ml-auto">
                                    <li className="nav-item active">
                                        <a onClick={handleNavBarCollapse} className="page-scroll " href="#home">Inicio</a>
                                    </li>
                                    <li className="nav-item">
                                        <a onClick={handleNavBarCollapse} className="page-scroll" href="#features">Herramientas</a>
                                    </li>
                                    <li className="nav-item">
                                        <a onClick={handleNavBarCollapse} className="page-scroll" href="#about">
                                            Beneficios
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a onClick={handleNavBarCollapse} className="page-scroll" href="#facts">
                                            Precios
                                        </a>
                                    </li>

                                    <li className="nav-item">
                                        <a onClick={handleNavBarCollapse} className="page-scroll" href="#contact">
                                            Contacto
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="navbar-btn d-none d-sm-inline-block">
                                <a className="main-btn" data-scroll-nav="0" href="#contact" rel="nofollow"> Prueba
                                    gratis
                                </a>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar