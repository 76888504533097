import React from "react";

import headerHero from "./../../assests/images/header-hero.png"
import Particles1 from "../common/Particles1";
const SectionStart = props => {

    return (
        <div id="home" className="header-hero bg_cover">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="header-hero-content text-center">
                            <h3 className="header-title wow fadeInUp" data-wow-duration="1.3s"
                                data-wow-delay="0.2s">Scala</h3>
                            <h2 className="header-sub-title wow fadeInUp" data-wow-duration="1.3s"
                                data-wow-delay="0.5s"> Optimiza tu Escuela, Inspira el Aprendizaje </h2>
                            <p className="text wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.8s">
                                Descubre cómo Scala puede transformar tu institución. ¡Empieza hoy mismo!
                            </p>
                            <a href="#contact" className="main-btn wow fadeInUp" data-wow-duration="1.3s"
                               data-wow-delay="1.1s">Solicita un demo </a>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="header-hero-image text-center wow fadeIn" data-wow-duration="1.3s"
                             data-wow-delay="1.4s">
                            <img src={headerHero} alt="hero" style={{marginBottom:"80px"}}/>
                        </div>
                    </div>
                </div>
            </div>
            <Particles1/>
            <div id="particles-1" className="particles">
                <canvas className="particles-js-canvas-el" style={{width: "100%", height: "100%"}} width="1343"
                        height="885"></canvas>
            </div>
        </div>
    )
}

export default  SectionStart