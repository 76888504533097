import React from "react";
import FormContact from "./FormContact";
 const SectionFormContact = props => {

     return (
        <>
            <section className="ws-section-spacing contact-bg" id="contact">
                <div className="container">
                    <FormContact/>
                </div>
            </section>
        </>
     )
}

export default SectionFormContact